/**
 * @description contains transaction information
 */
export class Client {

    firstName: string;
    lastName: string;
    address: string;
    msisdn: string;

    constructor(sender_first_name: string, sender_last_name: string, sender_address: string, sender_msisdn: string) {
        this.firstName = sender_first_name;
        this.lastName = sender_last_name;
        this.address = sender_address;
        this.msisdn = sender_msisdn;
    }

}
