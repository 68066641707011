/**
 * @description contains IRT information
 */
export class IrtCommission {

    currencyCode: string;
    serviceProvider: string;
    turnoverBalance: string;
    settlementBalance: string;
    balance: number;
    createdDate: Date;

    constructor(currencyCode: string, serviceProvider: string, turnoverBalance: string, settlementBalance: string, balance: number, createdDate: Date) {
        this.currencyCode       = currencyCode;
        this.serviceProvider    = serviceProvider;
        this.turnoverBalance    = turnoverBalance;
        this.settlementBalance  = settlementBalance;
        this.balance            = balance;
        this.createdDate        = createdDate;
    }
}
